import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Menu} from '../interface/menu';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private baseUrl = environment.apiBaseUrl;

  menusChanged = new EventEmitter<void>();

  constructor(private http: HttpClient) { }

  getMenuCollection(): Observable<Menu[]>{
    return this.http.get<Menu[]>(`${this.baseUrl}/get/menu`);
  }
  getSingleCollection(): Observable<Menu[]>{
    return this.http.get<Menu[]>(`${this.baseUrl}/get/singles`);
  }

  getSubMenuCollection(): Observable<Menu[]>{
    return this.http.get<Menu[]>(`${this.baseUrl}/get/submenus`);
  }
  getCollectionParent(): Observable<Menu[]>{
    return this.http.get<Menu[]>(`${this.baseUrl}/get/parent/menus`);
  }

  getLabelCollection(): Observable<Menu[]>{
    return this.http.get<Menu[]>(`${this.baseUrl}/get/labels`);
  }

  postSingle(data: any): Observable<any>{
    return this.http.post<Menu>(`${this.baseUrl}/create/single`, data);
  }

  postMenu(data: any): Observable<any>{
    return this.http.post<Menu>(`${this.baseUrl}/create/menu`, data);
  }

  postSubMenu(data: any): Observable<any>{
    return this.http.post<Menu>(`${this.baseUrl}/create/submenu`, data);
  }

  postLabel(data: any): Observable<any>{
    return this.http.post<Menu>(`${this.baseUrl}/create/label`, data);
  }
  putMenu(id: number, data: Menu): Observable<any>{
    return this.http.put<Menu>(`${this.baseUrl}/edit/menu/${id}`, data);
  }
  putSubMenu(id: number, data: Menu): Observable<any>{
    return this.http.put<Menu>(`${this.baseUrl}/edit/submenu/${id}`, data);
  }
  putSingle(id: number, data: Menu): Observable<any>{
    return this.http.put<Menu>(`${this.baseUrl}/edit/single/${id}`, data);
  }
  putLabel(id: number, data: Menu): Observable<any>{
    return this.http.put<Menu>(`${this.baseUrl}/edit/label/${id}`, data);
  }

  delete(id: number): Observable<Menu>{
    return this.http.delete<Menu>(`${this.baseUrl}/delete/menu/` + id);
  }

  getPermissionsByMenuId(id: any): Observable<any>{
    return this.http.get(`${this.baseUrl}/get/permissions/menu/${id}`);
  }

  deleteMultipleMenu(ids: number[]): Observable<Menu[]>{
    const options = {body: ids};
    return this.http.delete<Menu[]>(`${this.baseUrl}/delete/selected/menus`, options);
  }

  refreshMenus() {
    this.menusChanged.emit();
  }

}
