// has-permission.pipe.ts

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hasPermission'
})

export class HasPermissionPipe implements PipeTransform {
    transform(permission: string, userPermissions: any[]): boolean {
        return userPermissions.some(perm => perm.name === permission);
    }
}
