<div class="page-wrapper">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-12">
                <div class="login-card">
                    <div>
                        <div>
                            <a class="logo"  routerLink='/'>
                                <!--<img class="img-fluid for-light" src="assets/images/logo/ss_main_logo.png" height="100" width="400" alt="Smart School">
                                <img class="img-fluid for-dark" src="assets/images/logo/ss_main_logo_white.png" height="100" width="400" alt="Smart School">-->

                                <img class="img-fluid for-light" src="assets/images/logo/quidak.png" alt="Quidak">
                                <img class="img-fluid for-dark" src="assets/images/logo/quidak-white.png" alt="Quidak">
                            </a>
                        </div>
                        <div class="login-main">
                            <form class="theme-form" [formGroup]="resetPasswordForm" (ngSubmit)="userPasswordReset()">
                                <h4>{{ 'create your password'|translate }}</h4>
                                <div class="form-group">
                                    <label class="col-form-label">{{ 'new password'|translate }}</label>
                                    <input class="form-control"
                                           formControlName="newPassword"
                                           [type]="show ? 'text' : 'password'"
                                           name="login[password]"
                                           required="true"
                                           placeholder="*********">
                                    <div class="invalid-feedback fst-italic" *ngIf="fc['newPassword'].errors?.['minlength']">{{ 'password must be at least 5 characters long'|translate }}</div>

                                    <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                                    <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label">{{ 'confirm password'|translate }}</label>
                                    <input class="form-control"
                                           formControlName="confirmNewPassword"
                                           type="password"
                                           name="login[password]"
                                           required="true"
                                           placeholder="*********">
                                    <div class="invalid-feedback fst-italic" *ngIf="fc['confirmNewPassword'].errors?.['minlength']">{{ 'password must be at least 5 characters long'|translate }}</div>

                                </div>
                                <div class="form-group mb-0">
                                    <button class="btn btn-primary d-block w-100" type="submit" [disabled]=login>
                                        <ng-container *ngIf="!login; else isLogin">{{ 'done'|translate }}</ng-container>
                                        <ng-template #isLogin>
                                            <button class="btn btn-primary"><i class="fa fa-spin fa-spinner me-2"></i>{{ 'loading...'|translate }}</button>
                                        </ng-template>
                                    </button>
                                </div>
                                <p class="mt-4 mb-0">Already Have an account?<a class="ms-2" [routerLink]="'/auth/login'">{{ 'sign in'|translate }}</a></p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
