<div class="page-wrapper">
    <div class="container-fluid p-0">
        <!-- Unlock page start-->
        <div class="authentication-main mt-0">
            <div class="row">
                <div class="col-12">
                    <div class="login-card">
                        <div>
                            <div>
                                <a class="logo" href="#">
                                    <img class="img-fluid for-light" src="assets/images/logo/ss_main_logo.png" height="100" width="400" alt="Smart School">
                                    <img class="img-fluid for-dark" src="assets/images/logo/ss_main_logo_white.png" height="100" width="400" alt="Smart School">
                                </a>
                            </div>
<!--                            [type]="show ? 'text' : 'password'"-->
                            <div class="login-main">
                                <form class="theme-form" [formGroup]="otpVerificationForm" (ngSubmit)="loginUser()">
                                    <h4>OTP</h4>
                                    <div class="form-group">
                                        <label class="col-form-label">{{ 'enter otp'|translate }}</label>
                                        <input
                                                formControlName="opt"
                                                class="form-control"
                                                [type]="'text'"
                                                name="login[otp]"
                                                required=""
                                                placeholder="*********">
<!--                                        <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>-->
<!--                                        <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>-->
                                    </div>
                                    <div class="form-group mb-0">
<!--                                        If don't receive OTP?  Resend-->
                                        <div class="text-start mt-2 mb-2">
                                            <span class="reset-password-link">{{ 'if don\'t receive OTP?'|translate }}
                                                <a class="btn-link text-danger" (click)="resendOtp()" style="cursor: pointer;">{{ 'resend'|translate }}</a>
                                            </span>
                                        </div>

                                        <button class="btn btn-primary d-block w-100" type="submit" [disabled]=login>
                                            <ng-container *ngIf="!login; else isLogin">{{ 'log in'|translate }}</ng-container>
                                            <ng-template #isLogin>
                                                <button class="btn btn-primary"><i class="fa fa-spin fa-spinner me-2"></i>{{ 'loading...'|translate }}</button>
                                            </ng-template>
                                        </button>
                                    </div>
                                    <p class="mt-4 mb-0">{{ 'already Have an account?'|translate }}<a class="ms-2" [routerLink]="'/auth/login'">{{ 'sign in'|translate }}</a></p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Unlock page end-->
    </div>
</div>
