<div class="container-fluid">
  <div class="page-title" style="background: #C3C4D9">
    <div class="row">
      <div class="col-6">
        <h5>{{ title }}</h5>
      </div>
      <div class="col-6">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
<!--            <a [routerLink]="'/dashboard/default'">-->

                  <ng-template *ngIf="userCheck.isIsTeacherSystem == true || userCheck.isIsStudentSystem == true; else otherProfile">
                      <svg class="stroke-icon">
                          <use href="assets/svg/icon-sprite.svg#stroke-home"></use>
                      </svg>
                  </ng-template>

                  <ng-template #otherProfile>
                      <a  [routerLink]="'/dashboard/loading-module'" [queryParams]="{ layout: 'Singapore' }">
                          <svg class="stroke-icon">
                              <use href="assets/svg/icon-sprite.svg#stroke-home"></use>
                          </svg>
                      </a>
                  </ng-template>


          </li>
          <li class="breadcrumb-item" *ngFor="let item of items">{{ item }}</li>
          <li class="breadcrumb-item active">{{ active_item }}</li>
        </ol>
      </div>
    </div>
  </div>
</div>
