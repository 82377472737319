// // shared-data.service.ts
// import { Injectable } from '@angular/core';
//
// @Injectable({
//     providedIn: 'root',
// })
// export class SharedDataService {
//     private permissions: any[] = [];
//
//     constructor() {}
//
//     setPermissions(data: any[]): void {
//         console.log(data);
//         this.permissions = data;
//     }
//
//     getPermissions(): any[] {
//         console.log(this.permissions)
//         return this.permissions;
//     }
// }

// shared-data.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SharedDataService {
    private permissionsSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    permissions = this.permissionsSubject.asObservable();

    constructor() {}

    setPermissions(data: any[]): void {
        this.permissionsSubject.next(data);
    }

    getPermissions(): any[] {
        return this.permissionsSubject.getValue();
    }
}

